(function ($) {


const Init = (() => {

  let website      = {},
      load         = {},
      resize       = {},
      scroll       = {},
      mod          = {},
      scrollOption = {},
      parallax     = {},
      drawer       = {},
      navi         = {},
      wp           = {};

  website = {
    page          : document.body.dataset.page,
    url           : document.URL,
    winWidth      : window.innerWidth,
    winHeight     : window.innerHeight,
    scrollY       : window.pageYOffset,
    breakpoint    : { pc: 1200, tb: 992, sp: 768, ss: 576 },
    ua            : navigator.userAgent.toLowerCase(),
    isDesktop     : true,
    isFirst       : true,
    animationFrame: null,

    headTag       : $('.l-header'),

    act           : 'is-act',
    actOpen       : 'is-open',

    targetAnime : 'js-anime',
    actAnime    : 'is-anime',
    shiftPoint  : 0,
    shiftPc     : 5,
    shiftSp     : 10,
  };
  website.shiftPoint = website.breakpoint.tb;

  load = {
    functions: [],
    length   : 0,
  };

  resize = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };

  scroll = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };


  const WinLoad = {

    init() {
      window.addEventListener('load', function(e) {
        WinLoad.update();
      });

      console.log('!-- load init --!');
    },

    add(func) {
      load.functions.push(func);
      load.length = load.functions.length;
    },

    remove(func) {
      load.functions.splice(func, 1);
      load.length = load.functions.length;
    },

    update() {
      for ( let i = 0; i < load.length; i++ ) {
        let func = load.functions[i];
        func();
      }
    },

  }


  const WinResize = {

    init() {
      window.addEventListener('resize', function(e) {
        if ( !resize.isRunning ) {
          resize.isRunning = true;

          resize.animationFrame = window.requestAnimationFrame( WinResize.update );

          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinResize.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinResize.update();
          //   }, 1000/resize.fps);
          // }
        }
      });

      console.log('!-- resize init --!');
    },

    add(func) {
      resize.functions.push(func);
      resize.length = resize.functions.length;
    },

    remove(func) {
      resize.functions.splice(func, 1);
      resize.length = resize.functions.length;
    },

    update() {
      website.winWidth  = window.innerWidth;
      website.winHeight = window.innerHeight;
      website.ua        = navigator.userAgent.toLowerCase();

      for ( let i = 0; i < resize.length; i++ ) {
        let func = resize.functions[i];
        func();
      }

      resize.isRunning = false;
    },

  }


  const WinScroll = {

    init() {
      window.addEventListener('scroll', function(e) {
        if ( !scroll.isRunning ) {
          scroll.isRunning = true;

          scroll.animationFrame = window.requestAnimationFrame( WinScroll.update );

          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinScroll.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinScroll.update();
          //   }, 1000/scroll.fps);
          // }
        }
      });

      console.log('!-- scroll init --!');
    },

    add(func) {
      scroll.functions.push(func);
      scroll.length = scroll.functions.length;
    },

    remove(func) {
      scroll.functions.splice(func, 1);
      scroll.length = scroll.functions.length;
    },

    update() {
      website.winHeight = window.innerHeight;
      website.scrollY = window.pageYOffset;

      for ( let i = 0; i < scroll.length; i++ ) {
        let func = scroll.functions[i];
        func();
      }

      scroll.isRunning = false;
    },

  }


  const Modules = {

    init() {
      mod = {
        targetSmooth    : 'js-scroll',
        smoothSpeed     : 600,

        targetTab       : 'js-tabWrap',
        targetTabTrigger: 'js-tabTrg',
        targetTabContent: 'js-tabCont',

        targetAccordion : 'js-accordion',

        targetModal : 'js-modal',
        targetModalOpen : 'js-modalOpen',
        targetModalClose: 'js-modalClose',

        targetAlign     : 'js-align',
        targetAlignList : [],
        alignAddClass   : 'c-alignheight',

        targetOfi       : '.js-ofi .ofi',

        targetDateTimePicker : '.js-dtpicker',
      };

      let alignList = document.querySelectorAll('.' + mod.targetAlign);
      mod.targetAlignList = Array.prototype.slice.call(alignList, 0);

      this.iosCheck();
      this.deviceCheck();
      this.smoothScroll();
      this.tabBtn();
      this.accordionBtn();
      this.modalBtn();

      objectFitImages(mod.targetOfi);
    },

    iosCheck() {
      if ( website.ua.indexOf('ipad') > -1 || website.ua.indexOf('iphone') > -1 || (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document ) ) {
        $('body').addClass('ios');
      } else {
        $('body').removeClass('ios');
      }
    },

    deviceCheck() {
      if (
        website.ua.indexOf('iphone') > -1 ||
        website.ua.indexOf('ipod') > -1 ||
        website.ua.indexOf('android') > -1 && website.ua.indexOf('mobile') > -1
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc tablet').addClass('sp');
      } else if (
        website.ua.indexOf('ipad') > -1 ||
        website.ua.indexOf('Android') > -1 ||
        (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document )
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc sp').addClass('tablet');
      } else {
        website.isDesktop = true;
        $('body').removeClass('tablet sp').addClass('pc');
      }
    },

    smoothScroll() {
      $('.' + mod.targetSmooth + ':not([href=""])').on('click', function() {
        let href     = $(this).attr("href"),
            target   = $( (href == "#") ? 'html' : href ),
            shift    = ( (website.winWidth < website.shiftPoint) && (href != "#") ) ? website.headTag.outerHeight(): 0,
            position = target.offset().top - shift;

        $('body,html').animate({scrollTop: position}, mod.smoothSpeed, 'swing');
        return false;
      });
    },

    tabBtn() {
      $('.' + mod.targetTabTrigger).each(function(){
        let tabBtn = $(this).children('li').children('a');
        tabBtn.on('click',function(){
          let tabHref = $(this).attr('href'),
              tabObj = $(this).parent('li').siblings('li').children('a'),
              targetCont = $(this).closest('.'+mod.targetTab).find('.'+mod.targetTabContent),
              targetObj = targetCont.find(tabHref),
              contsObj = targetObj.siblings();
              tabObj.removeClass('is-act');
              $(this).addClass('is-act');
              contsObj.hide();
              targetObj.fadeIn();
              return false;
        });
        $(this).children('li').eq(0).children('a').click();
      });
    },

    accordionBtn() {
      $('.' + mod.targetAccordion).on('click', function(){
        if ( $(this).hasClass(website.act) ) {
          $(this).removeClass(website.act).next().stop().slideUp('fast');
          // $(this).removeClass(website.act);
        } else {
          $(this).addClass(website.act).next().stop().slideDown();
          // $(this).addClass(website.act);
        }
      });
    },

    modalBtn() {
      $('.'+mod.targetModalOpen).on('click',function(){
        let target = $(this).attr('href');
        $(target).fadeIn();
        return false;
      });
      $('.'+mod.targetModalClose).on('click',function(){
        $(this).closest('.'+mod.targetModal).fadeOut();
        return false;
      });
    },

    alignHeight() {
      let alignHeight  = 0;

      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = 'auto';
        target.classList.add(mod.alignAddClass);
        alignHeight = ( target.offsetHeight > alignHeight ) ? target.offsetHeight: alignHeight;
        target.classList.remove(mod.alignAddClass);
      });
      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = alignHeight + 'px';
      });
    },
    datetimePicker() {
      if($(mod.targetDateTimePicker).length){
        $.datetimepicker.setLocale('ja');
        var start = new Date();
        start.setDate(start.getDate() + 1);
        $(mod.targetDateTimePicker).attr('readonly','readonly');
        $(mod.targetDateTimePicker).datetimepicker({
          lang: 'ja',
          format:'Y/m/d',
          minDate : start,
          defaultDate: '',
          timepicker: false,
          scrollMonth : false,
          scrollInput : false,
          // disabledDates: [
          //   '2021/01/06', '2021/01/07', '2021/01/13', '2021/01/14', '2021/01/20', '2021/01/21', '2021/01/27', '2021/01/28',
          //   '2021/02/03', '2021/02/04', '2021/02/10', '2021/02/17', '2021/02/18', '2021/02/24', '2021/02/25',
          //   '2021/03/03', '2021/03/04', '2021/03/10', '2021/03/11', '2021/03/17', '2021/03/18', '2021/03/24', '2021/03/25', '2021/03/31',
          //   '2021/04/01', '2021/04/07', '2021/04/08', '2021/04/14', '2021/04/15', '2021/04/21', '2021/04/22', '2021/04/28',
          //   '2021/05/06', '2021/05/12', '2021/05/13', '2021/05/19', '2021/05/20', '2021/05/26', '2021/05/27',
          //   '2021/06/02', '2021/06/03', '2021/06/09', '2021/06/10', '2021/06/16', '2021/06/17', '2021/06/23', '2021/06/24', '2021/06/30',
          //   '2021/07/01', '2021/07/07', '2021/07/08', '2021/07/14', '2021/07/15', '2021/07/21',  '2021/07/28', '2021/07/29',
          //   '2021/08/04', '2021/08/05', '2021/08/12', '2021/08/18', '2021/08/19', '2021/08/25', '2021/08/26',
          //   '2021/09/01', '2021/09/02', '2021/09/08', '2021/09/09', '2021/09/15', '2021/09/16', '2021/09/22', '2021/09/29', '2021/09/30',
          //   '2021/10/06', '2021/10/07', '2021/10/13', '2021/10/14', '2021/10/20', '2021/10/21', '2021/10/27', '2021/10/28',
          //   '2021/11/04', '2021/11/10', '2021/11/11', '2021/11/17', '2021/11/18', '2021/11/24', '2021/11/25',
          //   '2021/12/01', '2021/12/02', '2021/12/08', '2021/12/09', '2021/12/15', '2021/12/16', '2021/12/22', '2021/12/23',],
          timepicker:false
        });
      }
    }
  }


  const Animation = {

    init() {
    },

    check() {
      $('.' + website.targetAnime + ':not(.' + website.actAnime + ')').each(function(){
        let self = $(this),
            targetPosition = self.offset().top,
            triggerPosition,
            shift;

        if( website.winWidth < website.shiftPoint ) {
          shift = website.winHeight / website.shiftSp;
        } else {
          shift = website.winHeight / website.shiftPc;
        }
        triggerPosition = targetPosition - website.winHeight + shift;

        if( website.scrollY > triggerPosition ){
          self.addClass(website.actAnime);
        }
      });
    },

  }


  // const PageScroll = {

  //   init() {
  //     scrollOption = {
  //       target    : document.getElementById('js-contbox'),
  //       translateY: 0,
  //       contHeight: 0,
  //       velocity  : 0.08,

  //       rootMargin : '0px',
  //       threshold  : 0.1,
  //     };

  //     this.observer();
  //   },

  //   set() {
  //     scrollOption.contHeight    = scrollOption.target.getBoundingClientRect().height - 1;
  //     document.body.style.height = Math.floor(scrollOption.contHeight) + 'px';
  //   },

  //   smooth() {
  //     this.set();

  //     let diff = website.scrollY - scrollOption.translateY;

  //     if ( diff !== 0 ) {
  //       if ( Math.abs(diff) > 1 ) {
  //         scrollOption.translateY += diff * scrollOption.velocity;
  //       } else {
  //         scrollOption.translateY = website.scrollY;
  //       }
  //       scrollOption.target.style.transform = 'translate3d(0, -' + scrollOption.translateY + 'px, 0)';
  //     }
  //   },

  //   observer() {
  //     let targets = Array.from( document.querySelectorAll('.' + website.targetAnime) ),
  //         options = {
  //           rootMargin : scrollOption.rootMargin,
  //           threshold  : scrollOption.threshold,
  //         };
  //     let observer = new IntersectionObserver(this.observerCallback, options);

  //     targets.forEach((target) => {
  //       if ( target.dataset.delay ) {
  //         target.style.transitionDelay = target.dataset.delay + 'ms';
  //       }

  //       observer.observe(target);
  //     });
  //   },

  //   observerCallback(entries, object) {
  //     entries.forEach(function(entry, i) {
  //       if (!entry.isIntersecting) return;
  //       entry.target.classList.add(website.actAnime);
  //       object.unobserve(entry.target);
  //     });
  //   },
  // }


  // const Parallax = {

  //   init() {
  //     parallax = {
  //       nodeList   : document.querySelectorAll('.js-parallax'),
  //       gap        : 0.08,

  //       decoList   : [],
  //     };

  //     let targets = Array.prototype.slice.call(parallax.nodeList, 0);
  //     targets.forEach((target) => {
  //       if ( target.dataset.para ) {
  //         let arr = target.dataset.para.split(',');
  //         parallax.decoList.push( [target, arr] );
  //       }
  //     });
  //   },

  //   decoImg() {
  //     parallax.decoList.forEach((target) => {
  //       let targetRect = target[0].getBoundingClientRect(),
  //           parentNode = document.querySelector(target[1][0]),
  //           parentRect = parentNode.getBoundingClientRect(),
  //           diff       = targetRect.top - parentRect.top,
  //           gap        = (target[1][3]) ? Number(target[1][3]): parallax.gap,
  //           translateX,
  //           translateY;

  //       if ( (targetRect.top - website.winHeight) <= 0 ) {
  //         if ( target[1][1] == 'X' ) {
  //           translateX = ( target[1][2] == 'reverse' ) ? -((targetRect.top - diff) * gap): (targetRect.top - diff) * gap;
  //           target[0].style.transform = 'translate3d(' + translateX + 'px, 0px, 0px)';
  //         } else {
  //           translateY = ( target[1][2] == 'reverse' ) ? -((targetRect.top - diff) * gap): (targetRect.top - diff) * gap;
  //           target[0].style.transform = 'translate3d(0, ' + translateY + 'px, 0)';
  //         }

  //       }
  //     });
  //   },

  // }


  const DrawerMenu = {

    init() {
      drawer = {
        panel     : 'js-so_panel',
        menu      : 'js-so_menu',
        duration  : 10,
        // openclass : 'slideout-open',
        toggleList: '#js-so_toggle',
        closeList : '#js-so_panel, #js-so_menu .js-scroll',
      };

      this.menu();
    },

    menu() {
      let slideoutInst = new Slideout({
        'panel': document.getElementById(drawer.panel),
        'menu': document.getElementById(drawer.menu),
        'duration': drawer.duration,
        // 'openclass': drawer.openclass,
      });

      let nodeList = document.querySelectorAll(drawer.toggleList);
      let node = Array.prototype.slice.call(nodeList,0);
      node.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.toggle();
        });
      });

      let nodeListClose = document.querySelectorAll(drawer.closeList);
      let nodeClose = Array.prototype.slice.call(nodeListClose,0);
      nodeClose.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.close();
        });
      });
    },

  }


  const Wordpress = {

    init() {
      wp = {
        editorTarget: 'js-editor',
        tableClass  : 'c-tbl_responsive',
        tableInnerClass  : 'c-tbl_responsive__inner',
        iframeclass : 'c-iframe',
      }

      this.wpEditor();
    },

    wpEditor() {
      if ( document.getElementById(wp.editorTarget) != null ) {
        $( '.' + wp.editorTarget + ' table' ).each(function() {
          $(this).wrap('<div class="' + wp.tableClass + '"><div class="' + wp.tableInnerClass + '"></div></div>');
        });
        $( '.' + wp.editorTarget + ' iframe' ).each(function() {
          $(this).wrap('<div class="' + wp.iframeclass + '"></div>');
        });
      }
      // if ( $('.' + wp.editorTarget).length ) {
      //   $( '.' + wp.editorTarget + ' table' ).each(function() {
      //     $(this).wrap('<div class="' + wp.tableClass + '"></div>');
      //   });
      //   $( '.' + wp.editorTarget + ' iframe' ).each(function() {
      //     $(this).wrap('<div class="' + wp.iframeclass + '"></div>');
      //   });
      // }
    }

  }


  const Slick = {

    init() {
    },

    topKey() {
      $('.js-key_anime').addClass('is-anime');
      $('.js-key_slide').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
        speed: 2000,
        dots:false,
        arrows:false,
        autoplay: true,
        fade: true,
        pauseOnFocus: false,
        pauseOnHover: false
      });
    },
    topNews(){
      $('.js-slick_news').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplaySpeed: 5000,
        speed: 2000,
        dots:false,
        arrows:true,
        autoplay: true,
        fade: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        responsive: [
          // {
          //   breakpoint: website.breakpoint.pc,
          //   settings: {
          //     slidesToShow: 3,
          //     slidesToScroll: 3,
          //     infinite: true,
          //     dots: true
          //   }
          // },
          {
            breakpoint: website.breakpoint.tb,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: website.breakpoint.sp,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots : true,
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      })
    }
  }

  const Top = {

    init() {
      Slick.topKey();
      Slick.topNews();
    },

    update() {
    },

  }


  const BasicPage = {

    init() {
    },

    update() {
    },

  }


  const App = {

    init() {
      this.set();
      // this.update();
    },

    set() {
      Modules.init();
      DrawerMenu.init();
      // PageScroll.init();
      Wordpress.init();

      if ( website.page == 'top' ) {
        Top.init();
      } else {
        // BasicPage.init();
      }


      WinLoad.add( Animation.check );
      WinLoad.add( Modules.datetimePicker );

      WinResize.add( Modules.iosCheck );
      WinResize.add( Modules.deviceCheck );

      WinScroll.add( Animation.check );


      WinLoad.init();
      WinResize.init();
      WinScroll.init();
    },

    // update() {
    //   website.animationFrame = window.requestAnimationFrame( App.update );
    //   website.winHeight      = window.innerHeight;
    //   website.scrollY        = window.pageYOffset;

    //   if ( website.isDesktop ) {
    //     PageScroll.smooth();

    //     if ( website.page == 'top' ) {
    //       Parallax.decoImg();
    //     }
    //   }
    //   // else {
    //   //   PageScroll.set();
    //   // }
    // },

  }


  App.init();

})();


})(jQuery);
